import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-request-definition',
  templateUrl: './request-definition.component.html',
  styleUrls: ['./request-definition.component.css']
})
export class RequestDefinitionComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RequestDefinitionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  requestDefinition = new FormGroup({
    notifications: new FormControl(false, [
    ]),
    word: new FormControl('', [
      Validators.minLength(2),
      Validators.required
    ]),
    requester: new FormControl('', [
      Validators.minLength(2)
    ])
  })

  ngOnInit(): void {
  }

  submitClick(): void {
    /**
     * show the progress bar ...maybe show notification, & when the request and been sent and completed.
     */
    console.log(this.requestDefinition.value);
    
    this.dialogRef.close();
  }

}
