import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exit-define-dialog',
  templateUrl: './exit-define-dialog.component.html',
  styleUrls: ['./exit-define-dialog.component.css']
})
export class ExitDefineDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  saveDefinitionForm(): void {
    console.info('saved')
  }

}
