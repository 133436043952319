import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder, FormArray, NgControlStatus, FormGroupDirective, NgForm } from '@angular/forms';
import { CallerService } from "../services/caller.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarTwoActionsData } from "../models/CustomSnackbarTwoActionsData";
import { CustomSnackbarTwoActionsComponent } from "../custom-snackbar-two-actions/custom-snackbar-two-actions.component";
import { ActivatedRoute } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';

/***
 * Error when invalid control is dirty, touched, or submitted.
 * 
 * from https://material.angular.io/components/input/examples#input-error-state-matcher
 * 
 * my test results - in my learning 
 * */
 export class TempErrorStateMatcher1 implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

export interface CountryInfo {
  name: string,
  iso_code_2: string,
  iso_code_3: string,
  name_abbr?: string,
  full_name?:string,
  aka_name?:string
}

/***
 * TODOs
 */
@Component({
  selector: 'app-define',
  templateUrl: './define.component.html',
  styleUrls: ['./define.component.css']
})
export class DefineComponent implements OnInit {

  constructor(private callerService: CallerService,
    private matSnackBar: MatSnackBar, private activatedRoute: ActivatedRoute) { }

    hideProgressBar: boolean = true;

    sentenceNumber: number = 0;

    _country = '';

    countries: CountryInfo[] = [
      {name: "Nigeria", iso_code_2: "NG", iso_code_3: "NGA" },
      {name: "Ethiopia", iso_code_2: "ET", iso_code_3: "ETH"},
      {name: "Egypt", iso_code_2: "EG", iso_code_3: "EGY"},
      {name: "DR Congo", iso_code_2: "CD", iso_code_3: "COD", full_name: "Democratic Republic of the Congo"},
      {name: "Tanzania", iso_code_2: "TZ", iso_code_3: "TZA"},
      {name: "South Africa", iso_code_2: "ZA", iso_code_3: "ZAF"},
      {name: "Kenya", iso_code_2: "KE", iso_code_3: "KEN"},
      {name: "Uganda", iso_code_2: "UG", iso_code_3: "UGA"},
      {name: "Algeria", iso_code_2: "DZ", iso_code_3: "DZA"},
      {name: "Sudan", iso_code_2: "SD", iso_code_3: "SDN"},
      {name: "Morocco", iso_code_2: "MA", iso_code_3: "MAR"},
      {name: "Angola", iso_code_2: "AO", iso_code_3: "AGO"},
      {name: "Mozambique", iso_code_2: "MZ", iso_code_3: "MOZ"},
      {name: "Ghana", iso_code_2: "GH", iso_code_3: "GHA"},
      {name: "Madagascar", iso_code_2: "MG", iso_code_3: "MDG"},
      {name: "Cameroon", iso_code_2: "CM", iso_code_3: "CMR"},
      {name: "Côte d'Ivoire", iso_code_2: "CI", iso_code_3: "CIV", full_name: "The Republic of Côte d'Ivoire", aka_name: "Ivory Coast"},
      {name: "Niger", iso_code_2: "NE", iso_code_3: "NER"},
      {name: "Burkina Faso", iso_code_2: "BF", iso_code_3: "BFA"},
      {name: "Mali", iso_code_2: "ML", iso_code_3: "MLI"},
      {name: "Malawi", iso_code_2: "MW", iso_code_3: "MWI"},
      {name: "Zambia", iso_code_2: "ZM", iso_code_3: "ZMB"},
      {name: "Senegal", iso_code_2: "SN", iso_code_3: "SEN"},
      {name: "Chad", iso_code_2: "TD", iso_code_3: "TCD"},
      {name: "Somalia", iso_code_2: "SO", iso_code_3: "SOM"},
      {name: "Zimbabwe", iso_code_2: "ZW", iso_code_3: "ZWE"},
      {name: "Guinea", iso_code_2: "GN", iso_code_3: "GIN"},
      {name: "Rwanda", iso_code_2: "RW", iso_code_3: "RWA"},
      {name: "Benin", iso_code_2: "BJ", iso_code_3: "BEN"},
      {name: "Burundi", iso_code_2: "BI", iso_code_3: "BDI"},
      {name: "Tunisia", iso_code_2: "TN", iso_code_3: "TUN"},
      {name: "South Sudan", iso_code_2: "SS", iso_code_3: "SSD"},
      {name: "Togo", iso_code_2: "TG", iso_code_3: "TGO"},
      {name: "Sierra Leone", iso_code_2: "SL", iso_code_3: "SLE"},
      {name: "Libya", iso_code_2: "LY", iso_code_3: "LBY"},
      {name: "Congo", iso_code_2: "CG", iso_code_3: "COG", full_name: "Republic of the Congo"},
      {name: "Liberia", iso_code_2: "LR", iso_code_3: "LBR"},
      {name: "Central African Republic", iso_code_2: "CF", iso_code_3: "CAF"},
      {name: "Mauritania", iso_code_2: "MR", iso_code_3: "MRT"},
      {name: "Eritrea", iso_code_2: "ER", iso_code_3: "ERI"},
      {name: "Namibia", iso_code_2: "NA", iso_code_3: "NAM"},
      {name: "Gambia", iso_code_2: "GM", iso_code_3: "GMB"},
      {name: "Botswana", iso_code_2: "BW", iso_code_3: "BWA"},
      {name: "Gabon", iso_code_2: "GA", iso_code_3: "GAB"},
      {name: "Lesotho", iso_code_2: "LS", iso_code_3: "LSO"},
      {name: "Guinea-Bissau", iso_code_2: "GW", iso_code_3: "GNB"},
      {name: "Equatorial Guinea", iso_code_2: "GQ", iso_code_3: "GNQ"},
      {name: "Mauritius", iso_code_2: "MU", iso_code_3: "MUS"},
      {name: "Eswatini", iso_code_2: "SZ", iso_code_3: "SWZ"},
      {name: "Djibouti", iso_code_2: "DJ", iso_code_3: "DJI"},
      {name: "Comoros", iso_code_2: "KM", iso_code_3: "COM"},
      {name: "Cape Verde", iso_code_2: "CV", iso_code_3: "CPV"},
      {name: "Sao Tome & Principe", iso_code_2: "ST", iso_code_3: "STP"},
      {name: "Seychelles", iso_code_2: "SC", iso_code_3: "SYC"}
    ]

    matcher = new TempErrorStateMatcher1();

    // get definitions for the words you hear on afro beats songs

    _countries: Map<String, CountryInfo> = new Map([
      ['NG', {name: "Nigeria", iso_code_2: "NG", iso_code_3: "NGA" }],
      ['ET', {name: "Ethiopia", iso_code_2: "ET", iso_code_3: "ETH"}],
      ['EG', {name: "Egypt", iso_code_2: "EG", iso_code_3: "EGY"}],
      ['CD', {name: "DR Congo", iso_code_2: "CD", iso_code_3: "COD", full_name: "Democratic Republic of the Congo"}],
      ['TZ', {name: "Tanzania", iso_code_2: "TZ", iso_code_3: "TZA"}],
      ['ZA', {name: "South Africa", iso_code_2: "ZA", iso_code_3: "ZAF"}],
      ['KE', {name: "Kenya", iso_code_2: "KE", iso_code_3: "KEN"}],
      ['UG', {name: "Uganda", iso_code_2: "UG", iso_code_3: "UGA"}],
      ['DZ', {name: "Algeria", iso_code_2: "DZ", iso_code_3: "DZA"}],
      ['SD', {name: "Sudan", iso_code_2: "SD", iso_code_3: "SDN"}],
      ['MA', {name: "Morocco", iso_code_2: "MA", iso_code_3: "MAR"}],
      ['AO', {name: "Angola", iso_code_2: "AO", iso_code_3: "AGO"}],
      ['MZ', {name: "Mozambique", iso_code_2: "MZ", iso_code_3: "MOZ"}],
      ['GH', {name: "Ghana", iso_code_2: "GH", iso_code_3: "GHA"}],
      ['MG', {name: "Madagascar", iso_code_2: "MG", iso_code_3: "MDG"}],
      ['CM', {name: "Cameroon", iso_code_2: "CM", iso_code_3: "CMR"}],
      ['CI', {name: "Côte d'Ivoire", iso_code_2: "CI", iso_code_3: "CIV", full_name: "The Republic of Côte d'Ivoire", aka_name: "Ivory Coast"}],
      ['NE', {name: "Niger", iso_code_2: "NE", iso_code_3: "NER"}],
      ['BF', {name: "Burkina Faso", iso_code_2: "BF", iso_code_3: "BFA"}],
      ['ML', {name: "Mali", iso_code_2: "ML", iso_code_3: "MLI"}],
      ['MW', {name: "Malawi", iso_code_2: "MW", iso_code_3: "MWI"}],
      ['ZM', {name: "Zambia", iso_code_2: "ZM", iso_code_3: "ZMB"}],
      ['SN', {name: "Senegal", iso_code_2: "SN", iso_code_3: "SEN"}],
      ['TD', {name: "Chad", iso_code_2: "TD", iso_code_3: "TCD"}],
      ['SO', {name: "Somalia", iso_code_2: "SO", iso_code_3: "SOM"}],
      ['ZW', {name: "Zimbabwe", iso_code_2: "ZW", iso_code_3: "ZWE"}],
      ['GN', {name: "Guinea", iso_code_2: "GN", iso_code_3: "GIN"}],
      ['RW', {name: "Rwanda", iso_code_2: "RW", iso_code_3: "RWA"}],
      ['BJ', {name: "Benin", iso_code_2: "BJ", iso_code_3: "BEN"}],
      ['BI', {name: "Burundi", iso_code_2: "BI", iso_code_3: "BDI"}],
      ['TN', {name: "Tunisia", iso_code_2: "TN", iso_code_3: "TUN"}],
      ['SS', {name: "South Sudan", iso_code_2: "SS", iso_code_3: "SSD"}],
      ['TG', {name: "Togo", iso_code_2: "TG", iso_code_3: "TGO"}],
      ['SL', {name: "Sierra Leone", iso_code_2: "SL", iso_code_3: "SLE"}],
      ['LY', {name: "Libya", iso_code_2: "LY", iso_code_3: "LBY"}],
      ['CG', {name: "Congo", iso_code_2: "CG", iso_code_3: "COG", full_name: "Republic of the Congo"}],
      ['LR', {name: "Liberia", iso_code_2: "LR", iso_code_3: "LBR"}],
      ['CF', {name: "Central African Republic", iso_code_2: "CF", iso_code_3: "CAF"}],
      ['MR', {name: "Mauritania", iso_code_2: "MR", iso_code_3: "MRT"}],
      ['ER', {name: "Eritrea", iso_code_2: "ER", iso_code_3: "ERI"}],
      ['NA', {name: "Namibia", iso_code_2: "NA", iso_code_3: "NAM"}],
      ['GM', {name: "Gambia", iso_code_2: "GM", iso_code_3: "GMB"}],
      ['BW', {name: "Botswana", iso_code_2: "BW", iso_code_3: "BWA"}],
      ['GA', {name: "Gabon", iso_code_2: "GA", iso_code_3: "GAB"}],
      ['LS', {name: "Lesotho", iso_code_2: "LS", iso_code_3: "LSO"}],
      ['GW', {name: "Guinea-Bissau", iso_code_2: "GW", iso_code_3: "GNB"}],
      ['GQ', {name: "Equatorial Guinea", iso_code_2: "GQ", iso_code_3: "GNQ"}],
      ['MU', {name: "Mauritius", iso_code_2: "MU", iso_code_3: "MUS"}],
      ['SZ', {name: "Eswatini", iso_code_2: "SZ", iso_code_3: "SWZ"}],
      ['DJ', {name: "Djibouti", iso_code_2: "DJ", iso_code_3: "DJI"}],
      ['KM', {name: "Comoros", iso_code_2: "KM", iso_code_3: "COM"}],
      ['CV', {name: "Cape Verde", iso_code_2: "CV", iso_code_3: "CPV"}],
      ['ST', {name: "Sao Tome & Principe", iso_code_2: "ST", iso_code_3: "STP"}],
      ['SC', {name: "Seychelles", iso_code_2: "SC", iso_code_3: "SYC"}]
    ])

    /**
     * we attach who the author of a sample sentence is
     * others can also add example sentence too.
     * people can upvote or downvote a sample sentence based on how easy it was for them to understand and if it was helpful
     */
    newDefinition = new FormGroup({
      spelling: new FormControl('', [
        Validators.minLength(2),
        Validators.required
      ]),
      author: new FormControl('', [
        Validators.minLength(2),
        Validators.required
      ]),
      definition: new FormControl('', [
        Validators.minLength(5),
        Validators.required,
        Validators.maxLength(300)
      ]),
      sampleSentences: new FormGroup({
      }),
      country_code: new FormControl()
    })


    get _sentences(): FormGroup {
      return this.newDefinition.get(['sampleSentences']) as FormGroup;
    }

  ngOnInit(): void {

    this.activatedRoute.data
          .subscribe(
            (_data) => { // extract country_code from result
              console.log('from api', _data.country_data.country_code)
              if (_data.country_data.country_code && this._countries.has(_data.country_data.country_code)) {
                this.newDefinition.get(['country_code'])?.setValue(_data.country_data.country_code)
              }
            },
            (err) => {
              console.error('err getting country code from api', err)
              // maybe ask them to set their country // make the input autocomplete
              // also if they aren't in Africa
            },
            () => {
              console.log('done... from api')
            },
          )
    
    console.log('new word form', this.newDefinition);

    // this.newDefinition.markAsUntouched()
    
    this.callerService.testApi().subscribe((data) => {
      console.log(data);
    }, (error) => {
      console.error('error fetching', error)
    }, () => {})

    this.newDefinition.get(['sampleSentences'])?.valueChanges.subscribe({
      next: (data) => {
        console.log(this._sentences.controls);
        
        console.log(data)
      }
    })

  }

  addSampleSentence(): void {
    this._sentences.addControl(`sentence-${this.sentenceNumber++}`, new FormControl('', [Validators.minLength(5), Validators.maxLength(250)]))
    console.log('added sample sentence', this.newDefinition.get(['sampleSentences']));

    console.log('new word form', this.newDefinition);
  }

  removeSampleSentence(name: string): void {
    console.log('removing index', name);
    this._sentences.removeControl(name)
    console.log(this._sentences);
    
  }

  defineWord() {
    console.log('defining', this.newDefinition.value);
    
    this.hideProgressBar = false;
    this.callerService.sendDefinition(this.newDefinition.value)
    .subscribe({
      next: (data) => {
        console.log('defineWord response', data); // show a notification that they can share it on SM
        this.openCustomSnackBar({
          message: 'Authored successfully. Share your definition!',
          error: false,
          definedWord: this.newDefinition.get(['spelling'])?.value
        }); // this.openSnackBar('Authored successfully.', 'Close'); // Share your definition!
        
        // this works, but the UX isn't smooth.
        this.newDefinition.reset({
          spelling: '', author: '', definition: ''
        })
        this.newDefinition.setControl('sampleSentences', new FormGroup({}));
        

        this.hideProgressBar = true;
      },
      error: (error) => {
        this.hideProgressBar = true;
        console.error('defineWord error fetching', error)
        this.openCustomSnackBar({message: 'We couldn\'t post that. Maybe try again.', error: true}); // this.openSnackBar('We couldn\'t post that', 'Close');
      },
      complete: () => { // after next
        this.hideProgressBar = true;
        console.info('we done');
      }
    })
  
  }

  openSnackBar(message: string, action: string) {
    /**
     * this is a MatSnackBarRef<TextOnlySnackBar>
     * Later, we should show one with pictures
     * like when we want to show a preview of the image that'll show if they share it.
     */
    let snackBarRef = this.matSnackBar.open(message, action, {
      // duration: 60000, // wait for 60 secs, cause we really want the author to interact with the notification
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    }); // should we show a count down timer of when the snackbar will auto delete?

    snackBarRef.afterDismissed().subscribe((e) => {
      console.log('The snack-bar was dismissed', e);
    });
    
    snackBarRef.onAction().subscribe(() => { // means we're showing it
      console.log('The snack-bar action was triggered!');
    });
  }

  openCustomSnackBar(data: CustomSnackbarTwoActionsData) {
    /**
     * this is a MatSnackBarRef<TextOnlySnackBar>
     * Later, we should show one with pictures
     * like when we want to show a preview of the image that'll show if they share it.
     */
    let snackBarRef = this.matSnackBar.openFromComponent(CustomSnackbarTwoActionsComponent, {
      // duration: 60000, // wait for 60 secs, cause we really want the author to interact with the notification
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      data: data,
      panelClass: ['test-here']
    }); // should we show a count down timer of when the snackbar will auto delete?

    snackBarRef.afterDismissed().subscribe((e) => {
      console.log('The snack-bar was dismissed', e);
    });
    
    snackBarRef.onAction().subscribe(() => { // means we're showing it
      console.log('The snack-bar action was triggered!');
    });
  }

  // get word() { return this.newDefinition.get('wordControle'); }

  // get definition() { return this.newDefinition.get('definitionControl'); }

  

}
