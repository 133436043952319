<main>
    <section>
        <div class="the-why">
            <h2 class="yes">Teach the world to speak like <span class="u">you</span> do.</h2> 
        </div>
    </section>
    <section>
        <div class="the-grid">
            <div>
                <h4 class="features heading heading-2">Find <mat-icon aria-hidden="false" aria-label="home icon">search</mat-icon></h4>
                <p class="feature-text">
                    Look up words/phrases the Oxford dictionary doesn't have.
                </p>
                <p>
                    <button mat-raised-button color="primary" (click)="focusOnSearch()">Find word or phrase</button>
                </p>
            </div>
            <div>
                <h4 class="features heading heading-2">Define <mat-icon aria-hidden="false" aria-label="edit icon">mode_edit</mat-icon></h4>
                <p class="feature-text">
                    Define words/phrases you know. So others know them too.
                </p>
                <p>
                    <button mat-raised-button color="primary" routerLink="/define">Define word or phrase</button>
                </p>
            </div>
            <div>
                <h4 class="features heading heading-2">Definition request <mat-icon aria-hidden="false" aria-label="saved search icon">saved_search</mat-icon></h4>
                <p class="feature-text">
                    Can't find a word or phrase? We'll ask our authors. <!--they should hover on authors and know who they are-->
                </p>
                <p>
                    <button mat-raised-button color="primary" (click)="openDefinitionDialog()">Request a definition</button>
                </p>
            </div>
        </div>

        <!-- <div *ngIf="randomWords && randomWords.length > 0">
            <h5>
                Checkout definition for these words
            </h5>
            <mat-chip-list
                class="example-chip"
                cdkDropList 
                cdkDropListOrientation="horizontal">
                <mat-chip
                    class="example-box"
                    cdkDrag
                    *ngFor="let word of randomWords">
                    {{ word.spelling }}
                </mat-chip>
                </mat-chip-list>

        </div> -->
    </section>
    <!--
        maybe use a design from https://bgjar.com/

        https://codepen.io/bosworthco/pen/YWBLpR (maybe footer or somewhere before footer)

        * include a button (call to action in each section)
        * make the now title, headers for the sections

        https://uxplanet.org/english-dictionary-app-redesign-a-ui-ux-case-study-851fbdce259c

        interesting font https://fonts.google.com/specimen/Zilla+Slab+Highlight?preview.text_type=custom#standard-styles
    -->
    <footer>
        <div>
            &copy; 2021 The African Dictionary
        </div>
        <div>
            <a href="#!">#</a>
        </div>
    </footer>
</main>