<!-- https://stackblitz.com/edit/angular-searchbar-zncxk9?file=app%2Fapp.component.html -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      attr.role="navigation"
      mode="side"
      >
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item routerLink="/"><mat-icon aria-hidden="false" aria-label="home icon" class="pad-right-small">home</mat-icon> Home</a>
      <a mat-list-item routerLink="/define"><mat-icon aria-hidden="false" aria-label="edit icon" class="pad-right-small">mode_edit</mat-icon> Define</a>
      <a mat-list-item (click)="openDialog()"><mat-icon aria-hidden="false" aria-label="request icon" class="pad-right-small">saved_search</mat-icon> Definition request</a><!-- should be a pop up, no need redirecting them to another page, as them if they want to be notified when someone defines the word. Also ask people if they want to be notified when people make request for word definitions. -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>

    <mat-toolbar color="primary">
      <div class="vertical-align">
        <button
          aria-label="icon-button with meun icon"
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          <!-- when they sign up, make meun like https://youtu.be/cbPe9_DKLYw?t=14 -->
        </button>
        <a mat-button class="hidden-mobile header-us" routerLink="/">African Lingo</a>
        
      </div>
      <span class="nav-spacer"></span>
      <div class="space-header-actions">

        <a mat-button (click)="openDialog()" class="hidden-mobile header-action">Definition request</a>
        <button mat-icon-button (click)="openDialog()" class="hidden-desktop header-action" aria-label="icon-button with pen icon">
          <mat-icon>saved_search</mat-icon>
        </button>
          

        <a mat-button routerLink="/define" class="hidden-mobile header-action">Define</a>
        <button mat-icon-button routerLink="/define" class="hidden-desktop header-action" aria-label="icon-button with pen icon">
          <mat-icon>create</mat-icon>
        </button>
      </div>
      <div class="search-dictionary">
        <mat-form-field class="search-field" #search>
          <mat-label [innerText]="searchLabelText"></mat-label>
          <input #searchInput matInput type="text" [formControl]="searchWord">
          <button mat-button *ngIf="searchWord.value.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="searchWord.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </mat-toolbar>
    <!-- Add Content Here -->
    <router-outlet></router-outlet>
    <!--// Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
