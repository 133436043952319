import { Component, OnInit } from '@angular/core';
import { CallerService } from "../services/caller.service";
import { RequestDefinitionComponent } from "../request-definition/request-definition.component";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Word } from '../models/Word';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  constructor(private dialog: MatDialog, private callerService: CallerService) { }

  randomWords?: Word[];

  ngOnInit(): void {
    this.callerService.getAllWords().subscribe({ // don't re-fetch on every navigation, cache with indexedDB
      next: (result: any) => {
        console.log('fetched words', result);
        this.randomWords = result;
      },
      error: (err: any) => {
        console.error('err getting words', err)
      },
      complete: () => {
        console.log('done getting words.');
      }
    })
  }

  openDefinitionDialog(): void {
    this.callerService.openDefinitionDialog();
  }

  focusOnSearch(): void {
    console.log('focusing on searh');
    
  }

}
