import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {BreakpointObserver, Breakpoints, MediaMatcher} from '@angular/cdk/layout';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { map, shareReplay, filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit { // is back up nav

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  constructor(private route: ActivatedRoute, private router: Router,
    changeDetectorRef: ChangeDetectorRef, private breakpointObserver: BreakpointObserver) {
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log('route params', params);
      
      // this.name = params['name'];
    });

    this.route.data.subscribe(data => {
      console.log('route data', data);
      
    })

    this.router.events.pipe(
      filter(_route => (_route instanceof NavigationEnd))
      ).subscribe((_route) => {
      console.log('_route data', _route);
      
    })

    // this.route.paramMap.pipe( // should be initializing a variable
    //   switchMap(params => {
    //     console.log('paramMap id', Number(params.get('id')));
    //     return '7'; // return this.service.getHeroes();
    //   })
    // );
  }

}
