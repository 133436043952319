import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Word } from '../models/Word';
import { environment } from '../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RequestDefinitionComponent } from '../request-definition/request-definition.component';

@Injectable({
  providedIn: 'root'
})
export class CallerService {

  /**
   * these should be an Enum, of constants
   */
  testUrl = `${environment.baseUrl}/hi`;
  postWordUrl = `${environment.baseUrl}/word/add`;
  getAllWordsUrl = `${environment.baseUrl}/word/all`;
  getWordDefinitionUrl = `${environment.baseUrl}/word/define/`;

  private handleError(error: HttpErrorResponse) {
    console.error('error: ', error)
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  private httpOptions = { // add timeout kini
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      // Authorization: 'my-auth-token'
    })
  };

  testApi(): Observable<any> {
    return this.http.get(this.testUrl).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  getAllWords(): Observable<any> {
    return this.http.get(this.getAllWordsUrl);
  }

  sendDefinition(word: Word) {
    return this.http.post(this.postWordUrl, word, this.httpOptions)
  }

  fetchDefinition(word: string): Observable<any> { // return type Word
    return this.http.get(this.getWordDefinitionUrl + word)
      // .pipe(this.handleError)
  }

  openDefinitionDialog(): void {
    const dialogRef = this.dialog.open(RequestDefinitionComponent, {
      width: '300px',
      // data: {name: 'some data', animal: 'this.animal'}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // this.animal = result;
    });
  }

  checkCountry(): Observable<any> {
    return this.http.get('https://ipapi.co/json/', {
      headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json"
      },
      observe: "body",
      responseType: "json"
    }).pipe(
      retry(3), // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );


  }

  constructor(private http: HttpClient, private dialog: MatDialog) { }
}
