import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, filter, switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap, NavigationEnd } from '@angular/router';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormControl } from '@angular/forms';
import { RequestDefinitionComponent } from "../request-definition/request-definition.component";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  searchWord: FormControl = new FormControl('');

  animal: string = 'animal';
  name: string = 'a name';

  @ViewChild('search')
  searchInput!: MatFormField;

  searchLabelText: String = 'Search'

  @ViewChild('searchInput')
  searchInputField!: MatInput;
  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private dialog: MatDialog, private route: ActivatedRoute, private router: Router, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {

    console.log('Are we in prod?', environment.production);

    this.router.events.pipe(
      filter(_route => (_route instanceof NavigationEnd))
      ).subscribe((_route) => {
      console.log('_route data', _route);
    })

    this.searchWord.valueChanges.pipe(
      filter((_s) => _s.length > 2),
    ).subscribe({
      next: (val: String) => {
        console.log(val);
      },
      error: () => {
        console.log('error searching?');
      },
      
    })

  }

  openDialog(): void {
    const dialogRef = this.dialog.open(RequestDefinitionComponent, {
      width: '300px',
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.animal = result;
    });
  }

  ngAfterViewInit(): void {
    
  }

}
/**
 * TODOs:
 * 1. if a search doesn't have a result, we request of it... and pass data automatically. Or just easily.
 */