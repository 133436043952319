import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DefineComponent } from "../define/define.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExitDefineDialogComponent } from "../exit-define-dialog/exit-define-dialog.component";
@Injectable({
  providedIn: 'root'
})
export class DefineGuard implements CanActivate, CanActivateChild, CanDeactivate<DefineComponent>, CanLoad {
  constructor(public dialog: MatDialog){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: DefineComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree { // actually returning boolean

    console.log('from guard deactivate', component.newDefinition);
    
    if (component.newDefinition.dirty) { // if unsubmitted from
      this.dialog.open(ExitDefineDialogComponent)
        .afterClosed().subscribe((result) => {
          console.log('after close', result);
          
      });
      return false; // last in this block
    } else {
      return true;
    }
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
