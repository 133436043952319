import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CallerService } from './caller.service';

@Injectable({
  providedIn: 'root'
})
export class DefineResolverService implements Resolve<any> {

  constructor(private callerService: CallerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.callerService.checkCountry();
  }

}
