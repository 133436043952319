import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackbarTwoActionsData } from "../models/CustomSnackbarTwoActionsData";
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-custom-snackbar-two-actions',
  templateUrl: './custom-snackbar-two-actions.component.html',
  styleUrls: ['./custom-snackbar-two-actions.component.css']
})
export class CustomSnackbarTwoActionsComponent implements OnInit {

  constructor(
    private snackBarRef: MatSnackBarRef<CustomSnackbarTwoActionsComponent>,
    private clipboard: Clipboard,
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackbarTwoActionsData) { }

  public copyState = "Copy link";

  ngOnInit(): void {
    console.log('data for snackbar-two-actions', this.data);
    
  }

  doAction(): void {
    // you can do some action here
    if (this.data.definedWord) {
      let _copied = this.clipboard.copy('https://dictionary.africa/define/' + this.data.definedWord)
      if (_copied) { // update text to copied
        this.copyState = "Copied!"
      }
    }
  }

  close(): void {
    console.log('closing snackbar-two-actions');
    
    this.snackBarRef.dismiss()
  }

}
