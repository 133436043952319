import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { NotFoundComponent } from "./not-found/not-found.component";
import { DefineComponent } from "./define/define.component";
import { DefineGuard } from "./guards/define.guard";
import { SideNavComponent } from "./side-nav/side-nav.component";
import { DefinitionComponent } from "./definition/definition.component";
import { DefinitionResolverService } from './services/definition-resolver.service';
import { DefineResolverService } from './services/define-resolver.service';

/**
 * Route order
 * 
 * The order of routes is important because 
 * the Router uses a first-match wins strategy when matching routes, 
 * so more specific routes should be placed above less specific routes. 
 * List routes with a static path first, followed by an empty path route, 
 * which matches the default route. 
 * The wildcard route comes last because it matches every URL 
 * and the Router selects it only if no other routes match first.
 * 
 * Links:
 * 1. https://angular.io/start/start-routing
 */
const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'side', component: SideNavComponent },
  { path: 'define/:word', component: DefinitionComponent, resolve: {
    definition: DefinitionResolverService
  } }, // :word or phrase
  { path: 'define', component: DefineComponent, canDeactivate: [DefineGuard], resolve: {
    country_data: DefineResolverService
  } },
  { path: '**', component: NotFoundComponent } // must be the last route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
