<div [hidden]="hideProgressBar">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<section *ngIf="noDefinitionFound">
  <div id="no-definition-found-div">
    So we don't have that word defined <u>yet</u>. Care to <a routerLink="/define">define it</a>? Or <button (click)="openDefinitionDialog()">create a definition request</button> so someone else defines it?
  </div>
</section>

<section *ngIf="_word">
  <div class="definition">
    <mat-card class="word-card"><!-- maybe use this design https://www.behance.net/gallery/90116455/MyLatina-mobile-app -->
      <mat-card-header>
        <mat-card-title>{{ _word?.spelling }}</mat-card-title>
        <mat-card-subtitle>{{ 'By ' + _word?.author + ' at ' + (_word?.timeAdded | date)}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p>
          {{ _word?.definition }}
        </p>

        <!-- <a [routerLink]="['/crisis-center', { foo: 'foo' }]">Crisis Center</a>
        <br>
        <a [routerLink]="['/hero', '345']">sth</a> -->

      </mat-card-content>
      <mat-card-actions>
        <button mat-button>MARK USEFUL</button>
        <button mat-button>SHARE</button>
      </mat-card-actions>
    </mat-card>
    
  </div>
</section>