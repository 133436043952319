import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Word } from '../models/Word';
import { CallerService } from "../services/caller.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-definition',
  templateUrl: './definition.component.html',
  styleUrls: ['./definition.component.css']
})
export class DefinitionComponent implements OnInit {

  constructor(private route: ActivatedRoute, private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private router: Router, private callerService: CallerService) { }

  _word?: Word = undefined;
  hideProgressBar: boolean = false;

  noDefinitionFound: boolean = false;

  /**
   * TODOs:
   * 1. Handle word not found.
   * 
   * Comtemplations:
   * 1. Should we load the data before displaying the page, or do that loading format with gliding rays of light
   */

  ngOnInit(): void {
    const id: Observable<string> = this.route.params.pipe(map(p => p.id));
    const url: Observable<string> = this.route.url.pipe(map(segments => segments.join('')));
    // route.data includes both `data` and `resolve`
    const user = this.route.data.pipe(map(d => d.user));

    // console.log('id', id, 'url', url, 'user', user);

    this.route.queryParams.subscribe(params => {
      console.log('params', params);
    });

    this.route.url.subscribe(url => {
      console.log('url', url);
    })

    // what does switchMap rxjs operator do?

    this.route.paramMap.subscribe(param => {
      
      if (param.has('word')) {
        // console.log('define word:', param.get(param.keys[0]));
        console.log('define word:', param.get('word'));
        /**
        let w = String(param.get('word')?.toString()); // String() cause param.get() returns null | string // or use path
        this.callerService.fetchDefinition(w)
        .subscribe({
          next: (result: Word) => {
            console.log('from server', result)
            this._word = result
          },
          complete: () => {
            console.log('done... from server')
            this.hideProgressBar = true
          },
          error: (err) => {
            console.error('err from defining word from server', err)
            // then stop loader and show error ...do sth when it's been loading for too long
            this.hideProgressBar = true;
            this.snackBar.open('Error getting definition', 'Close');
          }
        })
        */

        // console.log("route snapshot data", this.activatedRoute.snapshot.data);
        this.activatedRoute.data
          .subscribe(
            ({definition}) => { // extract definition from result
              this.hideProgressBar = true;
              if (definition) {
                console.log('from server', definition)
                this._word = definition
              } else {
                this.noDefinitionFound = true;
                // this.router.navigate(['/define']) // show a message saying we couldn't find
              }
            },
            (err) => {
              console.error('err from defining word from server', err)
              // then stop loader and show error ...do sth when it's been loading for too long
              this.hideProgressBar = true;
              this.snackBar.open('Error getting definition', 'Close');
            },
            () => {
              console.log('done... from server')
              this.hideProgressBar = true
            },
          )

      } else {
        // uhmmm...
        this.hideProgressBar = true
      }
      
      
    })
    
    
  }

  openDefinitionDialog(): void {
    this.callerService.openDefinitionDialog();
  }

}
