<div [hidden]="hideProgressBar">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="definition-form-container">
  <form [formGroup]="newDefinition" class="define-form">
    <h4 class="display-2">Define a new word</h4>
    <!-- Or don't show this ...maybe just auto put it -->
    <mat-form-field appearance="fill">
      <mat-label>Country</mat-label>
      <mat-select [(value)]="_country" formControlName="country_code">
        <mat-option *ngFor="let country of countries" [value]="country.iso_code_2">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  
      <mat-form-field class="form-full-width">
        <mat-label>Word/Phrase</mat-label>
        <input [errorStateMatcher]="matcher" formControlName="spelling" matInput placeholder="Ex. Pizza" value="">
      </mat-form-field>
  
      <mat-form-field class="form-full-width">
        <mat-label>Author</mat-label>
        <input [errorStateMatcher]="matcher" formControlName="author" matInput placeholder="What should we call you?" value="">
        <mat-hint align="start">What should we call <strong>you</strong>?</mat-hint>
      </mat-form-field>
    
      <mat-form-field class="form-full-width">
        <mat-label>Definition</mat-label>
        <textarea [errorStateMatcher]="matcher" matInput maxLength="300" formControlName="definition" placeholder="It makes me feel..."></textarea>
        <mat-hint align="start">Use <strong>your own words</strong> </mat-hint>
        <mat-hint align="end">{{newDefinition.value.definition.length}} / 300</mat-hint>
      </mat-form-field>
      
      <!-- example sentence -->
      <div formGroupName="sampleSentences">
        <div class="sample-sentence" *ngFor="let sen of _sentences.controls | keyvalue; let i=index">
          <mat-form-field class="form-full-width">
            <mat-label>Sample sentence (optional)</mat-label>
            <textarea [errorStateMatcher]="matcher" matInput maxLength="300" [formControlName]="sen.key" [placeholder]="'Sample sentence ' + (i+1)"></textarea>
            <mat-hint align="start"><strong>Use a relatable example</strong>. <span>Click <mat-icon>cancel</mat-icon> to delete</span></mat-hint>
            <mat-hint align="end">{{sen.value.value.length}} / 250</mat-hint>
          </mat-form-field>
          <button (click)="removeSampleSentence(sen.key)" mat-icon-button aria-label="icon-button with cancel icon">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </div>
  
      
  </form>
  
  <div class="button-actions">
    <div>
      <button (click)="addSampleSentence()" mat-raised-button color="primary" class="hidden-mobile">Add sample sentence</button>
      <button (click)="addSampleSentence()" mat-icon-button class="hidden-desktop" aria-label="icon-button with plus icon">
        <mat-icon>add</mat-icon>
        <span>Sample sentence</span>
      </button>
    </div>
  
    <button class="define-button" mat-raised-button (click)="defineWord()" [disabled]="!newDefinition.valid">Submit</button>
  </div>
</div>
