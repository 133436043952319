import { Component, HostListener } from '@angular/core';
import { CallerService } from './services/caller.service';
import { DefineResolverService } from './services/define-resolver.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'lingo-africa';

  @HostListener('window:keydown', ['$event']) // document:keydown
  handleKeyboardEvent(event: KeyboardEvent) {
    // console.log('Key was pressed', event);
    if (event.code == "Slash") {
      // focus on the search bar, maybe use parent child component coommunication...
    }
  }

  constructor() { }
}
