<h1 mat-dialog-title>Definition request</h1>
<div mat-dialog-content>
  <!-- if we have the word from empty search result, tell them, We'll ask authors for the definition [maybe make it a tiny text beneath the form input] -->
  <p>What's the word or phrase? We'd ask our authors for a definition.</p>
  <form [formGroup]="requestDefinition">
    <mat-form-field class="form-full-width">
      <mat-label>Word/Phrase</mat-label>
      <input matInput formControlName="word">
    </mat-form-field>

    <mat-form-field class="form-full-width">
      <mat-label>What name should we call you?</mat-label>
      <input matInput formControlName="requester" placeholder="Chuks">
      <mat-hint>(optional)</mat-hint>
    </mat-form-field>

    <mat-checkbox color="primary" matInput formControlName="notifications" class="notify">Notify me when there's a definition!</mat-checkbox>
  </form>
</div>
<div mat-dialog-actions align='end'>
  <button mat-button [mat-dialog-close]="'Cancled request'">Cancel</button>
  <button mat-raised-button (click)="submitClick()" color="primary" [disabled]="!requestDefinition.controls.word.valid">Submit</button>
</div>
