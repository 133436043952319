import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Word } from '../models/Word';
import { CallerService } from './caller.service';

@Injectable({
  providedIn: 'root'
})
export class DefinitionResolverService implements Resolve<Word> {

  constructor(private callerService: CallerService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Word> {
    return this.callerService.fetchDefinition(route.url[1].path); // route.url[1].path | route.paramMap.get('word')
  }
}
